.custom-wrapper {
  width: 100% !important;
  display: block !important;
  margin-bottom: 25px !important;
  height: 220px !important;
  padding-bottom: 400px !important;
}

.custom-editor {
  height: 500px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}
